'use client';

import React, { forwardRef, memo, Fragment, useMemo } from 'react';
import { clsx } from 'clsx';
import type {
  CSSProperties,
  Ref,
  DetailedHTMLProps,
  InputHTMLAttributes,
  ComponentProps,
} from 'react';
import type { RequireAtLeastOne } from 'type-fest';
import { tid } from '@vercel/geist-test-utils';
import { useFocusRing } from '@react-aria/focus';
import {
  useType,
  useId,
  getIxIconSize,
  type Size,
  type UseTypeTypes,
} from '../../utils';
import type { ErrorProps } from '../error';
import { Error, type ErrorObject } from '../error';
import { Label, type LabelProps } from '../label';
import { useDisabled } from '../../core/hooks/use-disabled-context';
import styles from './input.module.css';

/**
 * If 'label' is present and of type string without aria-label or aria-labelledby, `id` should also be passed in.
 */

type NativeInputProps = Omit<
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'prefix' | 'type' | 'size'
>;

export interface GenericInputProps {
  // Content
  value?: string;
  defaultValue?: string;

  // Overriding form settings
  label?: React.ReactNode;
  explicitId?: string;

  type?: UseTypeTypes;
  width?: CSSProperties['width'];
  size?: Size;
  rounded?: boolean;
  error?: ErrorObject | string | null | JSX.Element;
  // helpful for when you want to hide the error message but show the error styles
  showErrorMessage?: boolean;
  'data-testid'?: string;
}

type UnenforcedInputProps = NativeInputProps &
  GenericInputProps & {
    typeName?: string;
    type?: UseTypeTypes;
    prefix?: React.ReactNode;
    suffix?: React.ReactNode;
    prefixStyling?: boolean;
    suffixStyling?: boolean;
    prefixContainer?: boolean;
    suffixContainer?: boolean;
    // This should be `className`, but for backwards compatibility reasons
    // it needs to be prefixed with `className`.
    wrapperClassName?: string;
    innerWrapperClassName?: string;
  } & Pick<ComponentProps<typeof Label>, 'bypassCasing'>;

type InputPropsWithStringLabelAndId = Required<
  Pick<UnenforcedInputProps & { label: string }, 'label' | 'id'>
> &
  Omit<UnenforcedInputProps, 'label' | 'id'>;

export type InputProps =
  | InputPropsWithStringLabelAndId
  | RequireAtLeastOne<
      NativeInputProps &
        GenericInputProps & {
          typeName?: string;
          type?: UseTypeTypes;
          prefix?: React.ReactNode;
          suffix?: React.ReactNode;
          prefixStyling?: boolean;
          suffixStyling?: boolean;
          prefixContainer?: boolean;
          suffixContainer?: boolean;
          // This should be `className`, but for backwards compatibility reasons
          // it needs to be prefixed with `className`.
          wrapperClassName?: string;
          innerWrapperClassName?: string;
          bypassCasing?: boolean;
        },
      'aria-label' | 'aria-labelledby' | 'title' | 'id'
    >;

export const Input = memo(
  forwardRef(function Input(
    {
      explicitId,
      disabled,
      label,
      typeName = 'text',
      prefix,
      suffix,
      prefixStyling = true,
      prefixContainer = true,
      suffixStyling = true,
      suffixContainer = true,
      width,
      error,
      showErrorMessage = true,
      type,
      size,
      className,
      rounded,
      innerWrapperClassName,
      wrapperClassName,
      // Turn these off by default, can still be overriden via props
      spellCheck = false,
      autoCapitalize = 'none',
      autoComplete = 'off',
      autoCorrect = 'off',
      bypassCasing = false,
      ...props
    }: InputProps,
    ref: Ref<HTMLInputElement>,
  ) {
    const { focusProps, isFocusVisible } = useFocusRing({ isTextInput: true });
    const Wrapper = label ? Label : Fragment;
    const uniqueId = useId(`${String(props.id)}-`);
    const wrapperProps = {
      ...(label && {
        value: label,
        withInput: true,
        style: { width },
        // Sets `htmlFor` on the label, as some assistive technologies don't
        // correctly handle implicit labels.
        // https://www.w3.org/WAI/GL/WCAG20/WD-WCAG20-TECHS-20071102/H44.html
        id: uniqueId,
        wrapperClassName,
        bypassCasing,
      }),
    } as LabelProps;
    const isDisabled = useDisabled() || disabled;
    const inputId = useId('input-');

    // Always show error styling when error is set
    const typeCn = useType(error ? 'error' : type);

    // a11y
    const ariaLabel = props['aria-label'] || props.title;

    const getPrefix = (): React.ReactNode => {
      if (!prefix) return null;
      if (!prefixContainer) return prefix;
      return <span data-geist-input-prefix="">{prefix}</span>;
    };

    const getSuffix = (): React.ReactNode => {
      if (!suffix) return null;
      if (!suffixContainer) return suffix;
      return <span data-geist-input-suffix="">{suffix}</span>;
    };

    const iconSize = useMemo(() => getIxIconSize(size), [size]);

    return (
      <Wrapper {...wrapperProps}>
        <div
          className={clsx(
            styles.container,
            typeCn,
            {
              [String(styles.prefix)]: prefix,
              [String(styles.suffix)]: suffix,
              [String(styles.noPrefixStyle)]: !prefixStyling,
              [String(styles.noSuffixStyle)]: !suffixStyling,
              [String(styles.large)]: size === 'large',
              [String(styles.small)]: size === 'small',
              [String(styles.xSmall)]: size === 'xSmall',
              [String(styles.mediumSmall)]: size === 'mediumSmall',
              [String(styles.keyboardFocus)]: isFocusVisible,
              [String(styles.rounded)]: rounded,
              [String(styles.error)]: error,
            },
            innerWrapperClassName,
          )}
          data-geist-input-wrapper=""
          data-version="v1"
          style={
            {
              width,
              '--geist-icon-size': `${iconSize}px`,
            } as React.CSSProperties
          }
        >
          <input
            {...props}
            aria-describedby={
              error ? `${inputId}-error` : props['aria-describedby']
            }
            aria-invalid={Boolean(error)}
            aria-label={ariaLabel}
            autoCapitalize={autoCapitalize}
            autoComplete={autoComplete}
            autoCorrect={autoCorrect}
            className={clsx(styles.input, className)}
            data-geist-input=""
            disabled={isDisabled}
            id={explicitId ?? uniqueId}
            onBlur={(e): void => {
              focusProps.onBlur?.(e);
              props.onBlur?.(e);
            }}
            onFocus={(e): void => {
              focusProps.onFocus?.(e);
              props.onFocus?.(e);
            }}
            ref={ref}
            spellCheck={spellCheck}
            type={typeName}
          />
          {getPrefix()}
          {getSuffix()}
        </div>
        {showErrorMessage && error ? (
          <Error
            data-testid={
              props['data-testid']
                ? tid(props['data-testid'], 'error')
                : undefined
            }
            error={error}
            id={`${inputId}-error`}
            size={ErrorSizeMapping[size || 'small']}
            style={{ width, marginTop: 'var(--geist-gap-quarter)' }}
          />
        ) : null}
      </Wrapper>
    );
  }),
);

const ErrorSizeMapping: Record<
  Exclude<InputProps['size'], undefined>,
  ErrorProps['size']
> = {
  xSmall: 'small',
  small: 'small',
  mediumSmall: 'small',
  large: 'large',
};
